import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "swiftui-docs"
    }}>{`SwiftUI Docs`}</h1>
    <p><em parentName="p">{`An unofficial guide to Apple's SwiftUI.`}</em></p>
    <p>{`SwiftUI is great! But sifting through the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui"
      }}>{`official spec`}</a>{` can be daunting,
and learning exclusively from tutorials can feel incomplete.  `}</p>
    <p>{`For example, a tutorial tells you to style `}<inlineCode parentName="p">{`Text("Hello world!")`}</inlineCode>{` with `}<inlineCode parentName="p">{`.font(.title)`}</inlineCode>{`.
That's nice, but what are the other options besides `}<inlineCode parentName="p">{`.title`}</inlineCode>{`? What do these other options look like? `}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/font/3055727-title"
      }}>{`provided docs`}</a>{` aren't
always the most visual, and Xcode's built-in (Option+click) doc search feature isn't always the most helpful. `}</p>
    <p>{`Wouldn't it be great if we could `}<a parentName="p" {...{
        "href": "/elements/text#font"
      }}>{`scan the docs`}</a>{` and
instantly see how SwiftUI works? I thought so, which is why I'll take notes here as I learn
SwiftUI myself. Maybe you'll find this helpful too.`}</p>
    <p>{`This site isn't intended to encompass an exhaustive specification of SwiftUI and everything
else in the Apple developer ecosystem. Instead, think of it as a quick-start guide (with pictures!) to
hit the ground running.`}</p>
    <p>{`Am I missing something? Yes, because I just started. Did I get something horribly
wrong? Probably, because I rarely know what I'm doing. But that's all okay,
because this project is `}<a parentName="p" {...{
        "href": "https://github.com/Yihwan/swiftui-docs"
      }}>{`open-source`}</a>{` -
and contributions are welcome!`}</p>
    <p>{`If you have any questions or would otherwise like to get in touch,
feel free to send me a message at `}<a parentName="p" {...{
        "href": "mailto:hi@yihwan.kim"
      }}>{`hi@yihwan.kim`}</a>{` or find me on
most things @yihwan. `}</p>
    <p>{`Happy hacking! `}</p>
    <br />
    <h2 {...{
      "id": "future-content"
    }}>{`Future Content`}</h2>
    <ul>
      <li parentName="ul">{`Introduction to SwiftUI (View model, modifier precedence, and so on)`}</li>
      <li parentName="ul">{`Layout & Positioning: Stacks, LayoutPriority`}</li>
      <li parentName="ul">{`Navigation `}</li>
      <li parentName="ul">{`Lists`}</li>
      <li parentName="ul">{`Forms (inputs)`}</li>
      <li parentName="ul">{`Drawing (shapes, gradients, patterns)`}</li>
      <li parentName="ul">{`Animation`}</li>
      <li parentName="ul">{`State `}</li>
      <li parentName="ul">{`Other Important Data Stuff Beyond State`}</li>
      <li parentName="ul">{`Xcode & The Stages of Grief `}</li>
      <li parentName="ul">{`and more ...`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      